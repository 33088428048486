<h1 class="text-primary mb-sm">Skill Set</h1>
<div class="scroll-section">
  <div class="ta-justify p-sm panel-enclosure mb-md">
    <app-expand-collapse
      class="d-block w-100"
      [(model)]="programmingExpanded"
      [showPlus]="true"
      [headerStyleClass]="'project-sticky-header text-primary'"
      [icon]="'fa-solid fa-code'"
      [label]="'Programming'"
    >
      <div class="progress-container">
        <span class="progress-items" *ngFor="let skill of techSkills">
          <span class="progress-label">{{ skill.name }}</span>
          <span class="progress-width-container">
            <div
              class="progress-width"
              [style.height]="skill.proficiency + '%'"
            >
              <span class="text-value">{{ skill.proficiency / 10 }}/10</span>
            </div>
            <div
              class="progress-width"
              [style.height]="(skill.experience / 6.5) * 100 + '%'"
            >
              <span class="text-value">{{ skill.experience }} years</span>
            </div>
          </span>
        </span>
      </div>
    </app-expand-collapse>
  </div>
  <div class="ta-justify mt-sm p-sm panel-enclosure mb-md">
    <app-expand-collapse
      class="d-block w-100"
      [(model)]="libraryExpanded"
      [showPlus]="true"
      [headerStyleClass]="'project-sticky-header text-primary'"
      [icon]="'fa-solid fa-suitcase'"
      [label]="'Libraries / Frameworks'"
    >
      <div class="progress-container d-flex">
        <span class="progress-items" *ngFor="let skill of libSkills">
          <span class="progress-label">{{ skill.name }}</span>
          <span class="progress-width-container">
            <div
              class="progress-width"
              [style.height]="skill.proficiency + '%'"
            >
              <span class="text-value">{{ skill.proficiency / 10 }}/10</span>
            </div>
            <div
              class="progress-width"
              [style.height]="(skill.experience / 6.5) * 100 + '%'"
            >
              <span class="text-value">{{ skill.experience }} years</span>
            </div>
          </span>
        </span>
      </div>
    </app-expand-collapse>
  </div>
  <div class="ta-justify mt-sm p-sm panel-enclosure mb-md">
    <app-expand-collapse
      class="d-block w-100"
      [(model)]="softSkillsExpanded"
      [showPlus]="true"
      [headerStyleClass]="'project-sticky-header text-primary'"
      [icon]="'fa-solid fa-bars-progress'"
      [label]="'Soft Skills'"
    >
      <span class="pl-md d-i-b">
        <span class="tag" *ngFor="let skill of softSkills">{{ skill }}</span>
      </span>
    </app-expand-collapse>
  </div>
</div>
