<!-- <ng-template #aboutMe> -->
<h1 class="text-primary mb-sm">About Me</h1>
<div class="scroll-section">
  <div class="ta-justify p-sm panel-enclosure mb-md">
    <app-expand-collapse
      class="d-block w-100"
      [(model)]="aboutMeExpanded"
      [showPlus]="true"
      [headerStyleClass]="'project-sticky-header text-primary'"
      [label]="'Me in few lines'"
    >
      <p
        class="text-md mb-sm p-sm"
        *ngFor="let item of aboutMe; let idx = index"
        [ngClass]="{ 'mt-0': idx === 0 }"
      >
        {{ item }}
      </p>
    </app-expand-collapse>
  </div>
  <div class="ta-justify p-sm panel-enclosure mb-md">
    <app-expand-collapse
      class="d-block w-100"
      [(model)]="experienceExpanded"
      [showPlus]="true"
      [headerStyleClass]="'project-sticky-header text-primary'"
      [label]="'Work Experience'"
    >
      <div class="p-sm">
        <div
          class="card p-md bg-primary mb-md"
          *ngFor="let exp of experienceDetail"
        >
          <div class="d-f b-b-lt mb-sm pb-sm "
          [ngClass]="{'fd-col': globalService.deviceOrientation === 'portrait', 'justify-between a-c': globalService.deviceOrientation === 'landscape'}">
            <div class="d-f a-c">
              <img
                src="../../../../assets/images/{{ exp.logo }}"
                class="circle"
                *ngIf="exp.logo"
              />
              <span class="pl-sm fw-500 text-primary text-lg">
                {{ exp.companyName }}
              </span>
            </div>
            <div class="right fw-300">{{ exp.duration }}</div>
          </div>
          <div class="mb-sm text-md fw-400">Position: {{ exp.position }}</div>
          <div class="mb-sm text-sm">Skills: {{ exp.skills }}</div>
        </div>
      </div>
    </app-expand-collapse>
  </div>

  <!-- <p class="text-md mb-sm mt-0">
        I am an UI Developer, with around 6.5 years of experience in various UI
        technologies like HTML, CSS, Javascript, jQuery, Typescript, OOJS, Angular
        (7-13), React etc.
    </p> -->
  <!-- <p class="text-md mb-sm">
        I prefer, myself to be recognized as a problem solver, rather than just a
        Developer. I try not to be restricted by technologies. I like following
        and am good at recognizing patterns, which helps me in getting into any
        new technology or framework pretty quickly. Over the time, in my current
        organization, there were times, when I was simultaneously working in
        multiple projects with different Tech stack. Context switching comes very
        naturally to me. When challenged, I give my all, to over come the
        challenge. I love to constantly refactor my code. When ever I learn a
        better way to do the thing, I have done in past, I push to refactor the
        code. I don't believe in the saying "Don't touch it if it's working".
    </p> -->
  <!-- <p class="text-md mb-sm">
        I speak my mind. I come up clearly on my thoughts on a matter, and give
        the reasoning behind my thinking (mostly during Sprint Meetings). I love
        to understand workflows (rather than just coding whats given to me) and
        try to make them better from an end user experience point of view.
    </p> -->
  <!-- I have been working not just as a UI developer, but mostly as a Problem solver, which I pride myself upon.
        There has been many projects that I have been included in, irrespective of
        the technologies used, and my managers always had the faith that I will be able to perform and contribute,
        some times in multiple projects simultaneously, as I am very good
        at switching contexts and identifying and creating patterns in any technology I use. This has helped me
        build a image of a dependable, responsible and dynamic developer. Apart
        from these skills, my expertise in CSS have helped me carve a niche among other UI developers in our
        organization. -->
  <!-- <p class="text-md mb-sm">
        I have done my B.Sc. in IT & Management and my MBA in IT and Finance.
        During my initial days, I used to work as a Plant Manager for a PDW
        (Packaged Drinking Water) plant, for brand Aquasure (from Aquaguard -
        Eureka Forbes), managing around 40 people. But I always had a liking for
        IT, since my graduation days, which made me shift my career into
        development profile. Better late than never.
    </p> -->
  <!-- <p class="text-md mb-sm">
        I started my development career with basic Javascript, jQuery and CSS stuffs, building reusable libraries
        for professional work and trying my hands on building libraries and games with
        Vanilla Javascript for my own learning purpose. When I shifted to a new project, I got a chance to work
        starting in Angular 7. But I was mostly in charge of developing wireframe and
        very basic UI components like configurable Menu List, Filterable Tree List etc. We worked in Kendo Angular
        UI library, so most component came from there, but in between we kept
        having requirements for some components which did not exist in the Kendo library like Indeterminate
        Progress bar, Circular Progress bar etc. I took it upon myself and started
        developing those components and later made them as a Library and published it to npm. Apart from these
        stuffs, I used to created reusable components like Modal component with custom
        features like Close on Escape, perform some action on Enter click, created wrappers around Kendo library
        component for easier upgrading etc, all these while being the sole in-charge
        for the styling part of the whole application. We had our own style guide, based on which I had created
        multiple LESS files, some to handle reusable components styling, some to
        handle style for Kendo components style overrides, media queries etc.
      </p>
      <p class="text-md mb-sm">
        When I moved to my next project, the initial one month I worked as a helping resource to the project. I
        was in charge of fine tuning, enhancing and restructuring the existing
        codebase. I spent a lot of time, creating patterns, flows and rewriting a lot of components, sometimes
        merging them to make reusable components as much as I can. Over time I was
        the sole developer contributing to the application for about 3+ years. Over the time, I built many
        patterns and highly reusable components, which made the development much easier and faster.
        I, single handedly, contributed in implementing Access Control framework, Annotation framework, Custom
        View Saving, Elastic Search, Notification, Chat, Dashboards, Custom Theming, etc apart
        from regular development of around 8 modules and more than a hundred reusable components. Also took care
        of application performance through lazy loading, tree shaking, moving most reusable codes into
        base classes etc. We were using the PrimeNG library for most UI components, so I created wrapper
        components around them to provide additional capabilities like adding custom control
        or action to those components. Created multiple pipes and directive to take care of localization using
        Angular's inbuilt services. This is the project which helped me learn Angular extensively.
        Later more people came into the UI team, whom I had to train and mentor since last one year so that I
        could move to new projects.
      </p> -->
</div>
<!-- </ng-template> -->
