<a
  href="javascript:void[0]"
  class="expand-collapse-container d-f a-c secondary-color {{
    headerStyleClass
  }}"
  (click)="change()"
  [ngClass]="{ 'justify-between pr-s': showPlus, 'b-b': model }"
  [attr.id]="id"
>
  <span class="d-f a-c header">
    <span class="mr-sm" [ngClass]="icon" *ngIf="icon"></span>
    <span
      class="fas fa-chevron-down rotate-icon {{ styleClass }}"
      [ngClass]="{ rotate: model }"
      *ngIf="!showPlus"
    ></span>
    <span
      class="{{ styleClass }}"
      *ngIf="label"
      [ngClass]="{ 'pl-sm': !showPlus }"
    >
      {{label}}
    </span>
  </span>
  <ng-container *ngIf="showPlus">
    <!-- <span class="fas fa-chevron-down rotate-icon {{styleClass}}"
      [ngClass]="{'rotate': model}"></span> -->
    <span
      class="exapnd-collapse-icon {{ styleClass }}"
      [ngClass]="{ expanded: model, collapsed: !model }"
    ></span>
  </ng-container>
</a>

<div
  class="collapsible"
  #collapsible
  [ngClass]="{ collapsed: !model }"
  [@grow]="!model ? 'closed' : 'opened'"
>
  <ng-content></ng-content>
</div>
